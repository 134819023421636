body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar, .navbar-brand{
color: #F9F9F9 !important;
background: #344B5F;
}
.picture-text{
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
}
.hp-title{
  font-family: 'Abril Fatface';
  margin-top: 150px;
  margin-left: 25px;
  text-align: left;
  font-size: xx-large;
  color: #344B5F;
}

li{
  list-style: none;
}
.links{
  font-family: 'Abril Fatface';

  margin-top: 150px;
  /* margin-left: 800px; */
  margin-right: 10px;
  text-align: left;
  /* text-align: right; */
  font-size: large;
  color: #344B5F;
}

.logos{
  height: 25px;
  width: 25px;
}

a{
  color: #344B5F;
  font-size:large;
}
.me > img {
  border-radius: 400px;
  height: 300px;
  margin-top: 50px;
}

.aboutMe{
  /* border: 5px solid #344B5F; */
  color: #344B5F;
  /* box-shadow: inset;
  margin: 200px;
  margin-top: 50px; */
  font-family: 'Montserrat', sans-serif;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 10px;
  text-align: left;
  font-size: large;
  font-weight: bolder;
}
.blogPH{
  
}

.portfolio-container{
  margin: 25px;
}

.blogContainer{
  color: #344B5F;
  text-align: left;
  font-size: large;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  
}

.card-img{
  width: 100px;
}


@media only screen and (max-height: 568px) {

  .me > img{
    margin-top: 200px;
  }

  


  .portfolio-container{
    margin: 55px;
  }

  a{
    font-size: small;
  }

  .links {
    margin-top: 50px;
  }
} 